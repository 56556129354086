import { Disclosure } from '@headlessui/react'

const faqs = [
  {
    question: 'Do I really get unlimited design services?',
    answer: 'Yes! You can submit an unlimited number of requests to your design queue at anytime, and your designer will work through them one by one. Forget the days of paying per project or per hour. With The Design Project, you pay the same flat monthly fee no matter how much you use our services.',
  },
  {
    question: 'What is a realistic turnaround time?',
    answer: 'Usually in two business days—it really depends on the complexity and urgency of your request. Every time we work on a new project, we will align on when we can deliver!',
  },
  {
    question: 'I need my designs ASAP—can you help?',
    answer: 'Yes! You can submit an unlimited number of requests to your design queue at anytime, and your designer will work through them one by one. Forget the days of paying per project or per hour. With The Design Project, you pay the same flat monthly fee no matter how much you use our services.',
  },
  {
    question: 'What types of projects do you work on?',
    answer: 'We focus on products and apps. Working with products that are currently out in the world with users is our sweet spot. We implement UX best practices, user insights and create great UIs to improve product KPIs.',
  },
  {
    question: 'What if I want to cancel my service?',
    answer: 'You can cancel anytime—just let us know in an email and you are good!',
  },
]

export default function Faqs () {
  return (
    <section className="bg-neutral-50 pt-8 md:py-12">
      <div className="container px-6 mx-auto">
        <h3 className="text-secondary-jungle text-4xl md:text-5xl leading-[43.2px] md:leading-[52.8px] mb-10 md:mb-12">
          Frequently asked questions
        </h3>
      </div>
    
      <ul>
        {faqs.map((faq, index) => (
          <li key={index} className="even:bg-neutral-50 odd:bg-neutral-100">
            <div className="container px-6 mx-auto">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className={`${open ? 'font-semibold' : 'font-normal'} text-neutral-500 hover:font-semibold text-xl md:text-[28px] flex items-center justify-between w-full py-6`}>  
                      <span className="text-left">{faq.question}</span>
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path d="M33.3851 15.8841L20.8851 28.3841C20.769 28.5003 20.6311 28.5925 20.4794 28.6554C20.3276 28.7183 20.165 28.7507 20.0007 28.7507C19.8364 28.7507 19.6738 28.7183 19.522 28.6554C19.3703 28.5925 19.2324 28.5003 19.1163 28.3841L6.61632 15.8841C6.38177 15.6495 6.25 15.3314 6.25 14.9997C6.25 14.668 6.38177 14.3499 6.61632 14.1153C6.85087 13.8808 7.16899 13.749 7.50069 13.749C7.8324 13.749 8.15052 13.8808 8.38507 14.1153L20.0007 25.7325L31.6163 14.1153C31.7325 13.9992 31.8703 13.9071 32.0221 13.8442C32.1738 13.7814 32.3365 13.749 32.5007 13.749C32.6649 13.749 32.8276 13.7814 32.9793 13.8442C33.1311 13.9071 33.2689 13.9992 33.3851 14.1153C33.5012 14.2315 33.5933 14.3694 33.6562 14.5211C33.719 14.6728 33.7514 14.8355 33.7514 14.9997C33.7514 15.164 33.719 15.3266 33.6562 15.4783C33.5933 15.6301 33.5012 15.768 33.3851 15.8841Z" fill="#252323"/>
                      </svg>
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-6">
                      <p className="text-neutral-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </li>
        ))}
      </ul>
    </section>
  )
}